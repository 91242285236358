<template>
  <div class="right d-f f-d a-c">
    <h2>扫码登录</h2>
    <div class="qrcode-container">
      <div class="qrcode" ref="qrcode"></div>
      <img src="@/assets/images/wx_login_explain.png" class="explain" />
    </div>
    <div class="dust d-f f-d a-c j-c" v-if="needUpdate">
      <h4>二维码已失效</h4>
      <a-button type="primary" @click="update"> 点击刷新 </a-button>
    </div>
    <div class="dust d-f f-d a-c j-c" v-if="hasScan">
      <h4>登录中...</h4>
    </div>
    <p>请使用 微信扫一扫 扫描二维码登录</p>
    <router-link to="/login">返回登录</router-link>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { getUuid, checkUuid } from "@/api/login.js";
import { getUserInfo } from "@/api/acl/user.js";
import { setCookie } from "@/utils/cookie.js";
export default {
  name: "WxLogin",
  data() {
    return {
      uuid: "",
      timer: null,
      needUpdate: false,
      hasScan: false,
    };
  },
  methods: {
    //生成二维码
    bindQRCode() {
      new QRCode(this.$refs.qrcode, {
        text: "https://cloud.smarteboard.cn/wx/scan?uuid=" + this.uuid,
        width: 140,
        height: 140,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
      this.$refs.qrcode.removeAttribute("title");
    },
    //得到uuid
    getUuid() {
      getUuid().then((res) => {
        this.uuid = res.data.uuid;
        console.log(this.uuid);
        this.bindQRCode();
        this.timer = setInterval(() => {
          this.checkUuid();
        }, 1000);
      });
    },
    //检查uuid状态
    checkUuid() {
      checkUuid({ uuid: this.uuid }).then((res) => {
        //扫码成功
        if (res.data.ret === 3) {
          this.hasScan = false;
          this.needUpdate = false;
          clearInterval(this.timer);
          this.timer = null;
          let { access_token, token_type, refresh_token, scope } = res.data;
          setCookie("access_token", access_token, 7);
          setCookie("token_type", token_type, 7);
          setCookie("refresh_token", refresh_token, 7);
          setCookie("scope", scope, 7);
          this.$ls.set("access_token", access_token, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
          this.$ls.set("token_type", token_type, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
          this.$ls.set("refresh_token", refresh_token, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
          this.$ls.set("scope", scope, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
          getUserInfo().then((result) => {
            this.$message.success("登录成功");
            this.$ls.set("userInfo", result.data, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
            this.$router.push("/enter");
          });
        }
        //失效
        else if (res.data.ret === 2) {
          clearInterval(this.timer);
          this.timer = null;
          this.needUpdate = true;
          this.hasScan = false;
        }
        //已经扫码
        else if (res.data.ret === 1) {
          this.hasScan = true;
          this.needUpdate = false;
        }
      });
    },
    //点击刷新
    update() {
      this.$refs.qrcode.innerHTML = "";
      this.needUpdate = false;
      this.getUuid();
      this.timer = setInterval(() => {
        this.checkUuid();
      }, 1000);
    },
  },
  mounted() {
    this.getUuid();
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped lang="less">
.right {
  width: 445px;
  height: 613px;
  background-image: url("~@/assets/images/login_right_bac.png");
  background-size: 100% 100%;
  padding: 110px 48px 0;
  box-sizing: border-box;
  position: relative;
  h2 {
    font-size: 24px;
    font-family: "PingFang SC";
    font-weight: bold;
    color: rgba(6, 98, 141, 0.85);
  }
  .qrcode-container {
    margin-top: 30px;
    height: 140px;
    width: 140px;
    position: relative;
    .qrcode {
      position: absolute;
      left: 0px;
      transition: left 1s;
      cursor: pointer;
    }
    .explain {
      width: 167px;
      position: absolute;
      left: 70px;
      top: -20px;
      opacity: 0;
      transition: all 1s;
    }
  }
  .qrcode-container:hover .qrcode {
    left: -80px;
  }
  .qrcode-container:hover .explain {
    opacity: 1;
  }
  .dust {
    width: 435px;
    height: 160px;
    position: absolute;
    top: 178px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.93);
  }
  p {
    margin-top: 35px;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
}
</style>
